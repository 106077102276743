import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import NavigationItem from './NavigationItem'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import gsap from 'gsap'
import { ReactComponent as Logo } from '../../assets/images/logoNew.svg'
import { Power4, Timeline } from 'gsap/gsap-core'
import { Link } from 'gatsby'
import { styleVariables } from '../../assets/styles/globalStyles'

const SiteLogo = styled(Logo)`
  width: 450px;
  transform: translateY(5px);
  margin-left: 20px;
  margin-bottom: 20px;

  @media only screen and (max-width: 768px) {
    width: 300px;
    margin-bottom: 20px;
  }
`

const NavigationWrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10 !important;
  background-color: var(--primaryDark);
  height: 135px;
  box-shadow: 3px 0 3px 2px rgba(0, 0, 0, 0.2);

  @media only screen and (max-width: 768px) {
    height: 90px;
  }

  @media only screen and (min-width: 768px) {
    justify-content: space-between;
    padding-right: 20px;
  }
`

const StyledMenuIcon = styled(MenuIcon)`
  /* --top: 50px; */
  position: absolute;
  top: var(--top);
  right: 10px;
  cursor: pointer;
  opacity: 1;
  transform: rotate(0);
  transition: all 0.2s ease !important;
  font-size: 34px;
  color: ${styleVariables.primary};

  &.opened {
    transform: rotate(180deg) !important;
    opacity: 0;
  }

  @media only screen and (max-width: 768px) {
    /* --top: 10px; */
  }

  @media only screen and (min-width: 768px) {
    display: none !important;
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  --top: 20px;
  position: absolute;
  top: var(--top);
  right: 20px;
  cursor: pointer;
  opacity: 0;
  z-index: 10;

  transform: rotate(0);
  transition: all 0.2s ease !important;

  &.opened {
    transform: rotate(360deg) !important;
    opacity: 1;
  }
  @media only screen and (max-width: 768px) {
    --top: 10px;
  }

  @media only screen and (min-width: 768px) {
    display: none !important;
  }
`

const NavigationList = styled.ul`
  --width: 380px;
  list-style-type: none;
  text-align: right;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--width);
  height: 100vh;
  background-color: #ffffff;
  font-size: 25px;
  padding: 80px 30px 10px;

  transform: translateX(var(--width));
  transition: all 0.3s ease;
  opacity: 0;
  z-index: 9000;

  pointer-events: none;

  @media only screen and (max-width: 768px) {
    width: 100vw;
    overflow-y: scroll;
    font-size: 28px;

    &.opened {
      transform: translateX(0 - var(--width));
      opacity: 1;
      pointer-events: all;
    }
  }

  @media only screen and (min-width: 768px) {
    position: static;
    width: unset;
    transform: none;
    flex-direction: row;
    text-align: center;
    opacity: 1 !important;
    pointer-events: all;
    background-color: transparent;
    height: unset;
    padding: 0;
    top: unset;
    right: unset;
    transform: translateY(10px);
    font-size: 18px;
    gap: 15px;
    svg {
      fill: white;
    }
    a {
      color: white;
    }
  }
`

const Overlay = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: none;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.3s ease;
  overflow: hidden;

  &.opened {
    opacity: 0.5;
    pointer-events: all;
  }

  @media only screen and (min-width: 768px) {
    display: none;
  }
`

const SiteName = styled.span`
  font-size: 1.7rem;
  color: #007cba;
  display: block;
  padding: 10px;
`

const Navigation = () => {
  const [menu, setMenu] = useState({ opened: false })
  const menuWrapper = useRef(null)

  useEffect(() => {
    const listItems = menuWrapper.current.querySelectorAll('li')
    const tl = new Timeline({ paused: true })
    gsap.defaults({ ease: Power4.easeOut })
    tl.play()
    tl.fromTo(
      listItems,
      {
        opacity: 1,
        y: 0,
      },
      {
        opacity: 1,
        y: 0,
        // stagger: 0.167,
      }
    )
    if (menu.opened) {
      tl.play()
    } else {
      tl.reverse()
    }
  }, [menu.opened])

  const handleClick = () => {
    setMenu({ opened: !menu.opened })
  }

  return (
    <NavigationWrapper>
      <Link to="/">
        <SiteLogo />
      </Link>
      <NavigationList
        onClick={handleClick}
        ref={menuWrapper}
        className={menu.opened ? 'opened' : ''}
      >
        <NavigationItem to="/#audiobooki">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M20 12v-1.707c0-4.442-3.479-8.161-7.755-8.29-2.204-.051-4.251.736-5.816 2.256A7.933 7.933 0 0 0 4 10v2c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2h2V10a5.95 5.95 0 0 1 1.821-4.306 5.977 5.977 0 0 1 4.363-1.691C15.392 4.099 18 6.921 18 10.293V20h2c1.103 0 2-.897 2-2v-4c0-1.103-.897-2-2-2z"></path>
            <path d="M7 12h2v8H7zm8 0h2v8h-2z"></path>
          </svg>{' '}
          <span>Audiobooki</span>
        </NavigationItem>

        <NavigationItem to="/#nagrania-video">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M18 7c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-3.333L22 17V7l-4 3.333V7zm-1.998 10H4V7h12l.001 4.999L16 12l.001.001.001 4.999z"></path>
          </svg>{' '}
          <span>Video</span>
        </NavigationItem>

        <NavigationItem to="/#gminy">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="m12 17 1-2V9.858c1.721-.447 3-2 3-3.858 0-2.206-1.794-4-4-4S8 3.794 8 6c0 1.858 1.279 3.411 3 3.858V15l1 2zM10 6c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2z"></path>
            <path d="m16.267 10.563-.533 1.928C18.325 13.207 20 14.584 20 16c0 1.892-3.285 4-8 4s-8-2.108-8-4c0-1.416 1.675-2.793 4.267-3.51l-.533-1.928C4.197 11.54 2 13.623 2 16c0 3.364 4.393 6 10 6s10-2.636 10-6c0-2.377-2.197-4.46-5.733-5.437z"></path>
          </svg>{' '}
          <span>Odkrywaj</span>
        </NavigationItem>

        <NavigationItem to="mailto:kontakt@ziemiaminska.pl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M20 2H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h3v3.767L13.277 18H20c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm0 14h-7.277L9 18.233V16H4V4h16v12z"></path>
            <path d="M11 14h2v-3h3V9h-3V6h-2v3H8v2h3z"></path>
          </svg>{' '}
          <span>Zaproponuj</span>
        </NavigationItem>

        <StyledCloseIcon
          onClick={handleClick}
          style={{ fontSize: 34, color: styleVariables.primary }}
          className={menu.opened ? 'opened' : ''}
        />
      </NavigationList>
      <StyledMenuIcon
        className={menu.opened ? 'opened' : ''}
        style={{ fontSize: 34, color: styleVariables.primary }}
        onClick={handleClick}
      />
      <Overlay onClick={handleClick} className={menu.opened ? 'opened' : ''} />
    </NavigationWrapper>
  )
}

export default Navigation
