import React from 'react'
import { Link } from 'gatsby'
const { default: styled } = require('styled-components')

const StyledNavigationItem = styled.li`
  opacity: 0;
  padding: 10px;
  border-radius: 3px;
  transition: background-color 0.2s ease;
  a {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

const NavigationItem = ({ to, children }) => (
  <StyledNavigationItem>
    <Link to={to}>{children}</Link>
  </StyledNavigationItem>
)

export default NavigationItem
