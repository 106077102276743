import { createGlobalStyle } from 'styled-components'

export const styleVariables = {
  //   primary: '#659cee',
  primary: '#eba75c',
  primaryDark: '#394256',
  textColor: '#2e2e2e',
}

const GlobalStyle = createGlobalStyle`
    // variables
    html {
        --primary: ${styleVariables.primary};
        --primaryDark: ${styleVariables.primaryDark};
        --textColor: ${styleVariables.textColor};
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }

    body {
        margin: 0;
        font-family: "Poppins", sans-serif;
        line-height: 1.8;
        overflow-x: hidden;
        color: var(----primaryDark);
        background-color: white;
    }

    button {
        padding: 0;
        cursor: pointer;
    }

    ul {
        padding: 0;
        margin: 0;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    h1, h2, h3, h4 {
        color: var(----primaryDark);
    }

    p {
        color: var(----textColor);
        font-weight: 300;
    }
`

export default GlobalStyle
