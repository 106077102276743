import React from 'react'
import styled from 'styled-components'
import GlobalStyle from '../assets/styles/globalStyles'
import Footer from '../components/Footer/Footer'
import Navigation from '../components/Navigation/Navigation'

const SiteSpacing = styled.aside`
  height: 125px;

  @media only screen and (max-width: 768px) {
    height: 80px;
  }
`

const MainLayout = ({ children }) => (
  <>
    <GlobalStyle />
    <Navigation />
    <SiteSpacing />
    {children}
    {/* <Footer /> */}
  </>
)

export default MainLayout
