import { Container } from '@material-ui/core'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { styleVariables } from '../../assets/styles/globalStyles'
import { footerContent } from '../../data/footerContent'
import { ReactComponent as Mkidn } from '../../assets/images/mkidn.svg'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const StyledFooter = styled.footer`
  padding: 50px 0;
  background-color: var(--primaryDark);
  color: white;
`

const FooterGrid = styled.main`
  display: grid !important;
  grid-gap: 40px;
  grid-template-columns: repeat(4, 1fr);

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`

const FooterCard = styled.ul`
  list-style-type: none;

  li {
    font-weight: 300;
    font-size: 13px;
  }
`

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      {
        logoniepodlegla: file(
          relativePath: { regex: "/universal/" }
          name: { regex: "/niepodlegla-logo/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <>
      <StyledFooter>
        <Container>
          <FooterGrid>
            {footerContent.map((card, index) => (
              <FooterCard key={index}>
                <a
                  style={{ color: styleVariables.primary }}
                  href={card.link ? card.link : ''}
                  target={card.target ? '_blank' : ''}
                >
                  {card.name}
                </a>
                {card.content.map((element, idx) => (
                  <li key={idx}>
                    {element.target ? (
                      <a
                        href={element.link}
                        target={element.target ? '_blank' : ''}
                      >
                        {element.title}
                      </a>
                    ) : (
                      <Link
                        to={element.link}
                        target={element.target ? '_blank' : ''}
                      >
                        {element.title}
                      </Link>
                    )}
                  </li>
                ))}
              </FooterCard>
            ))}
          </FooterGrid>
          {/* <hr
            style={{
              border: 'none',
              borderTop: `1px solid ${styleVariables.primary}`,
              marginTop: 20,
              marginBottom: 20,
            }}
          /> */}
          {/* <Mkidn style={{ maxWidth: 500 }} /> */}
        </Container>
      </StyledFooter>
      <section>
        <Container>
          <Img fluid={data.logoniepodlegla.childImageSharp.fluid} />
        </Container>
      </section>
    </>
  )
}

export default Footer
